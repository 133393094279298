@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);

/* General Buttons */

.button_standalone {
  width: 185px;
  height: 40px;
  background: linear-gradient(to bottom, #4eb5e5 0%,#389ed5 100%); /* W3C */
  border: none;
  border-radius: 5px;
  position: relative;
  border-bottom: 4px solid #2b8bc6;
  color: #fbfbfb;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  text-shadow: 1px 1px 1px rgba(0,0,0,.4);
  font-size: 15px;
  text-align: left;
  text-indent: 5px;
  box-shadow: 0px 3px 0px 0px rgba(0,0,0,.2);
  cursor: pointer;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
}

.button_standalone:active {
  box-shadow: 0px 2px 0px 0px rgba(0,0,0,.2);
  top: 1px;
}
.button_standalone:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    border-top: 20px solid #187dbc;
    border-bottom: 20px solid #187dbc;
    border-left: 16px solid transparent;
    border-right: 20px solid #187dbc;
    position: absolute;
    opacity: 0.6; 
    right: 0;
    top: 0;
    border-radius: 0 5px 5px 0;  
  }
  
  
  .button_standalone.back {
    text-align: right;
    padding-right: 12px;
    box-sizing: border-box;
  }

  .button_standalone.back:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    border-top: 20px solid #187dbc;
    border-bottom: 20px solid #187dbc;
    border-right: 16px solid transparent;
    border-left: 20px solid #187dbc;
    position: absolute;
    opacity: 0.6; 
    left: 0;
    top: 0;
    border-radius: 5px 0 0 5px;    
  }

  .button_standalone.site {
    width: 40px;
    text-align: center;
    text-indent: 0;
  }

  .button_standalone.site:after{
    display: none;
  }
  
  .holder {
    width: 400px;
    background: #efefef;
    padding: 30px 10px;
    box-sizing: border-box;
    margin: 0 auto;
    margin-top: 20px;  
    text-align: center;
  }

  h1 {
    font: 400 16px 'Open Sans';
    text-transform: uppercase;
    color: #999;
    text-shadow: 1px 1px 1px #fff;
    margin-bottom: 30px;
  }   

  .carousel-item {
    height: 65vh;
    min-height: 350px;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .class1{
    background-image:  url('https://source.unsplash.com/RCAhiGJsUUE/1920x1080');
  }
  .class2{
    background-image: url('https://source.unsplash.com/wfh8dDlNFOk/1920x1080');
  }
  .class3{
    background-image: url('https://source.unsplash.com/lHGeqh3XhRY/1920x1080');
  }

  .container1{
    position: absolute;
    top: 28%;
    left: 44%;
  }
  
  /* .stand_alone_input{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
   */