.MuiButton-outlinedPrimary {
    align-items: center !important;
    padding-left: 26px !important;
    padding-right: 26px !important;
    color: #3f51b5 !important;
    border: 1px solid rgba(63, 81, 181, 0.5) !important;
    margin-top: 4px !important;
}

.MuiTypography-body1 {
    display: inline-block !important;
}



.tabeedit {
    margin-left: 50% !important;
}

.react-tel-input {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    position: relative;
    width: 35% !important;
}