
b { /* used for event dates/times */
  margin-right: 3px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.button-add{
  margin-left: 966px;
  margin-bottom: -55px;
}
.demo-app-main {
  flex-grow: 1;
  padding: 3em;
  margin-top: 35px;
}

.fc { /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}