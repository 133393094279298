.login_btn {
    width: 100%;
    font-size: 135%;
    font-weight: 600;
}

.signup_label {
    font-size: 120%;
    font-weight: 600;
    letter-spacing: 1px;
}

.signup_btn {
    margin: 6% 0%!important;
    width: 100%;
    border-radius: 30px !important;
}

.orr {
    width: 20%;
    text-align: center;
}

.orlabel {
    font-size: 120%;
    font-weight: 600;
}

.backpagelink {
    padding: 6% 0%;
}