.otptimer {
    text-align: center;
}

[type="checkbox"] {
    vertical-align: middle;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.checkbox {
    font-size: 100%;
}

@media only screen and (max-width: 600px) {
    .checkbox {
        font-size: 75%;
    }
}

/* .login-form{
    background: url(../../image/LoginBG1.svg) no-repeat;
    background-size:cover;
    height: 100vh;
    display:flex;
}
.login-card{ 
    max-width: 419px;
    height: auto;
    width: 100%;
    background-color: #fff;
    margin: auto;
    left: 20em;
    position: relative;
    box-shadow: 2px 3px 13px 1px #767676c4;
}
.login-card h1,.login-card p {
    text-align: center;
    color: #29487d;
    font-weight: 900 !important;
}

.ctm_btn_set {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
}

.ctm_btn_set.facebook {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
    background-color: #29487d !important;
}

.ctm_btn_set.google {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
    background-color: #d74b3c !important;
}

.ctm_btn_set.linkdin {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
    background-color: #007bb6 !important;
}


.head {
    display: flex;
    justify-content: space-evenly;
}

.ctm_btn_set {
    background-color: #ccc;
    padding: 20px;
    border-radius: 100%;
    min-width:auto !important;
}

span.MuiButton-startIcon.MuiButton-iconSizeMedium {
    margin: 0;
}

.login-card .left-col .head{
    text-align: center;
    padding-bottom: 20px;
}
.login-card .left-col .head img{
    width: 100%;
    margin-bottom: 10px;
}
.login-card .left-col .MuiButton-contained{
    width: 94%;
}

.login-card .right-col{
    padding: 24px;
}
.login-card .right-col .head{
    text-align: center;
    padding-bottom: 20px;
}
.login-card .right-col .MuiTextField-root{
    margin-bottom: 20px;
}
.login-card .right-col h4{
    font-size: 25px;
    color: #1328a0;
    margin: 10px 0px;
}
.login-card .right-col p{
    color: #616060;
    font-size: 12px;
}
.ctm_btn_set.footer {
    width: 100%;
    border-radius: 0px;
    font-weight: 900;
    padding: 10px;
    font-size: 15px;
    color: #6c757d;
    background: #fff;
    border: 1px solid #6c757dba;
    box-shadow: unset;
}
.ctm_btn_set.footer:hover {
    background: #fff;
}
.otp_class .MuiTextField-root:first-child {
    padding: 0 14px 0 0px;
}

.otp_class {
    display: flex;
}
.footer p {
    font-size: 13px !important;
    text-align: inherit;
    word-spacing: 2px;
}
.footer p span {
    color: #007bb6 !important;
} */

.ui.dropdown .menu>.item {
    font-size: 90% !important;
}

/* .ui.dropdown .menu>.item>.flag,
.ui.dropdown .menu>.item>.icon,
.ui.dropdown .menu>.item>.image,
.ui.dropdown .menu>.item>.label,
.ui.dropdown .menu>.item>img,
.ui.dropdown>.text>.flag,
.ui.dropdown>.text>.icon,
.ui.dropdown>.text>.image,
.ui.dropdown>.text>.label,
.ui.dropdown>.text>img {
 margin-left: -12%;
 float: none;
 margin-right: .78571429rem;
} */

.emailotp {
    margin-top: 1% !important;
    margin-left: -7% !important;
}

.MuiDialogContent-root:first-child {
    padding-top: 0px !important;
}

.MuiDialogContent-root {
    flex: 1 1 auto;
    padding: 0px 6px !important;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.styles_otpInput__2umfM {
    width: 100% !important;
    height: auto !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    outline: none !important;
    border-radius: 0% !important;
    text-align: center !important;
    margin-top: 10px !important;
    font-size: 100% !important;
}

/* .ui.selection.active.dropdown .menu{
    width:250% !important;
} */

.line {
    height: 1px;
    width: 100%;
    background-color: #E0E0E0;
    margin-top: 10px
}

.orr {
    font-size: 100%;
    font-weight: 600;
}

.login_btn {
    width: 100%;
    font-size: 135%;
    font-weight: 600;
}

/* .formSignupLeftCol {
    background: url(../../image/buttons/signin/signup.png) no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 95% 90%;
    font-family: "Roboto";
    height: auto;
    display: flex;
    margin-top: 5%;
} */

.signup_label {
    font-size: 160%;
    color: rgb(255, 255, 255);
    font-weight: 600;
    letter-spacing: 2px;
}

.switch_sent {
    text-align: center;
    font-weight: 600;
}

.special-label {
    display: none !important;
}

/* .react-tel-input .form-control{
    border-bottom: 0px solid black !important;
    border-left: 0 !important;
    border-right: 0px !important;
    border-top: 0px !important;
} */

/* .react-tel-input .form-control{
    width: 120px !important;
} */