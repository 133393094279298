/* .login-form{
    background: url(../../image/LoginBG1.svg) no-repeat;
    background-size:cover;
    height: 100vh;
    display:flex;
}
.login-card{ 
    max-width: 419px;
    height: auto;
    width: 100%;
    background-color: #fff;
    margin: auto;
    left: 20em;
    position: relative;
    box-shadow: 2px 3px 13px 1px #767676c4;
}
.login-card h1,.login-card p {
    text-align: center;
    color: #29487d;
    font-weight: 900 !important;
}

.ctm_btn_set {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
}

.ctm_btn_set.facebook {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
    background-color: #29487d !important;
}

.ctm_btn_set.google {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
    background-color: #d74b3c !important;
}

.ctm_btn_set.linkdin {
    color: #fff;
    border-radius: 50%;
    padding: 0.8em;
    background-color: #007bb6 !important;
}


.head {
    display: flex;
    justify-content: space-evenly;
}

.ctm_btn_set {
    background-color: #ccc;
    padding: 20px;
    border-radius: 100%;
    min-width:auto !important;
}

span.MuiButton-startIcon.MuiButton-iconSizeMedium {
    margin: 0;
}

.login-card .left-col .head{
    text-align: center;
    padding-bottom: 20px;
}
.login-card .left-col .head img{
    width: 100%;
    margin-bottom: 10px;
}
.login-card .left-col .MuiButton-contained{
    width: 94%;
}

.login-card .right-col{
    padding: 24px;
}
.login-card .right-col .head{
    text-align: center;
    padding-bottom: 20px;
}
.login-card .right-col .MuiTextField-root{
    margin-bottom: 20px;
}
.login-card .right-col h4{
    font-size: 25px;
    color: #1328a0;
    margin: 10px 0px;
}
.login-card .right-col p{
    color: #616060;
    font-size: 16px;
}
.ctm_btn_set.footer {
    width: 100%;
    border-radius: 0px;
    font-weight: 900;
    padding: 10px;
    font-size: 15px;
    color: #6c757d;
    background: #fff;
    border: 1px solid #6c757dba;
    box-shadow: unset;
}
.ctm_btn_set.footer:hover {
    background: #fff;
}
.otp_class .MuiTextField-root:first-child {
    padding: 0 14px 0 0px;
}

.otp_class {
    display: flex;
}
.footer p {
    font-size: 13px !important;
    text-align: inherit;
    word-spacing: 2px;
}
.footer p span {
    color: #007bb6 !important;
} */
/* .formForgetLeftCol {
    background: url(../../image/buttons/signin/forgot.png) no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    height: 70vh;
    display: flex;
} */

.forgotbtn{
    width: 100%;
    font-size: 17px !important;
    font-family: "Roboto" !important;
    color: #ffffff !important;
    background: #3f51b5 !important;
    border: 1px solid #3f51b5 !important;
    box-shadow: unset;
    margin-top: 5% !important;
    border-radius: 30px !important;
}

.or{
    text-align: center !important;
    padding: 3% 0%;
}

.validUser{
    padding: 6% 0%;
    margin: 4% auto;
    text-align: center;
}
.panget{
    margin-left: 15px !important;
}