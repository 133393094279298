.login-form{
    background-color: #1c78a1;
    height: 100vh;
    display: flex;
}
.login-card{ 
    
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin: auto;
}
.login-card .left-col{
    background-color: #e4f0ff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 50px 30px;
    
}
.login-card .left-col .head{
    text-align: center;
    padding-bottom: 20px;
}
.login-card .left-col .head img{
    width: 100%;
    margin-bottom: 10px;
}
.login-card .left-col .MuiButton-contained{
    width: 94%;
}

.login-card .right-col{
    padding: 50px;
}
.login-card .right-col .head{
    text-align: center;
    padding-bottom: 20px;
}
.login-card .right-col .MuiTextField-root{
    margin-bottom: 20px;
}
.login-card .right-col h4{
    font-size: 25px;
    color: #3f51b5;
    margin-bottom: 10px;
}
.login-card .right-col p{
    color: #616060;
    font-size: 16px;
}

.login-card .right-col .condition{ 
    float:left;
}
.login-card .right-col .forgot-password{
    float:right;
    margin-top: 10px;
    color: 'blue';
}
.login-card .right-col .footer{
    width: 100%;
    float: left;
    padding-top: 20px;
}
.login-card .right-col .footer .MuiButtonBase-root{
    width: 100%;
    float: left;
}
