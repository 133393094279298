.mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header, .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-week-days {
    background: 0 solid #e5e7eb;
}

.mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: Black;
    font-size: 20px !important;
}

.mbsc-ios.mbsc-calendar {
    text-align: center;
    background: #fff;
    color: white;
    font-size: 20px !important;
}

.mbsc-calendar-week-day.mbsc-ios.mbsc-ltr {
    text-align: center !important;
    background: #5b21b6;
}
.mbsc-calendar-cell.mbsc-calendar-day.mbsc-ios.mbsc-ltr.mbsc-calendar-day-labels 
 {
     color:white;
    
       
}
.mbsc-calendar-cell.mbsc-calendar-day.mbsc-ios.mbsc-ltr.mbsc-calendar-day-labels div ~ div div
 {
     color: black;
 
    
}
.mbsc-ios.mbsc-calendar-width-md.mbsc-calendar-day-labels.mbsc-calendar-day-inner{
    min-height: 5.85em !important;
}

.mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-day-inner {
    min-height: 5.2em !important;
}
.mbsc-ios.mbsc-calendar-day {
    border-top-style: solid;
    border-top-width: 3px;
}
.mbsc-ios.mbsc-calendar-height-md .mbsc-calendar-day {
    border-left-style: solid;
    border-left-width: 3px;
}

.mbsc-ios.mbsc-calendar-label-text {
    color: #5b21b6 !important;
    background-color: #e5e7eb;
    text-align: center;
}