@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto-Light.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Roboto !important";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
  font-size: 14px !important;
}
.p0 {
  padding: 0!important;
}
.pl0 {
  padding: 0!important;
}
.pt0{
  padding-top: 0px!important;
}
.pr0{
  padding-right: 0px!important;
}
.pb0{
  padding-bottom: 0px!important;
}
.pl0{
  padding-left: 0px!important;
}

.pt1{
  padding-top: 5px!important;
}
.pr1{
  padding-right: 5px!important;
}
.pb1{
  padding-bottom: 5px!important;
}
.pl1{
  padding-left: 5px!important;
}

.pt2{
  padding-top: 10px!important;
}
.pr2{
  padding-right: 10px!important;
}
.pb2{
  padding-bottom: 10px!important;
}
.pl2{
  padding-left: 10px!important;
}
.pt3{
  padding-top: 20px!important;
}
.pr3{
  padding-right: 20px!important;
}
.pb3{
  padding-bottom: 20px!important;
}
.pl3{
  padding-left: 20px!important;
}
.pt4{
  padding-top: 30px!important;
}
.pr4{
  padding-right: 30px!important;
}
.pb4{
  padding-bottom: 30px!important;
}
.pl4{
  padding-left: 30px!important;
}
.pt5{
  padding-top: 40px!important;
}
.pr5{
  padding-right: 40px!important;
}
.pb5{
  padding-bottom: 40px!important;
}
.pl5{
  padding-left: 40px!important;
}
font,
.MuiButton-root {
  font-family: "Roboto";
  padding: 4px 5px !important;
}

code {
  font-family: "Roboto";
}

.MuiDialog-paperWidthSm {
  max-width: 600px !important;
  margin-left: 25rem !important;
}

.styles_otpWrapper__ZaQlv {
  display: flex !important;
  margin-left: -65px !important;
}

.MuiStepper-horizontal {
  border-top: 5px #7254a3 solid;
}

.makeStyles-circle-229 {
  width: 17px;
  height: 17px;
}

.PrivateNotchedOutline-legendLabelled-230,
.MuiSelect-outlined.MuiSelect-outlined,
label#standard-secondary-label,
.MuiInputLabel-outlined {
  font-size: 14px !important;
}
.MuiInputLabel-formControl {
  top: -6px !important;
}

.PrivateNotchedOutline-root-228 {
  top: 0px !important;
}

textarea {
  border-radius: 4px;
  border-color: #c4c4c4;
  height: 137px !important;
}

.MuiInputBase-root {
  margin-bottom: -4px !important;
  width: 100%;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #c4c4c4;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.MuiButton-label {
  text-transform: capitalize;
}

a {
  display: block;
  font-size: 20px;
}


.MuiCollapse-container.information-parent {
  border: none;
  background-color: #f5f3f3;
}

.textfeild_txtbox {
  /* padding: 0% 0% 2% 0%; */
  margin-bottom: 2% !important;
}

.login_txtlabel {
  font-size: 100%;
  font-weight: 600;
  margin-top: 2%;
}

.login_header {
  font-size: 150%;
  font-weight: 600;
  padding: 0% 0% 2% 0%;
}

.login_subheader {
  font-size: 125%;
  font-weight: 600;
  padding: 0% 0% 2% 0%;
}

.social_header {
  font-size: 120%;
  font-weight: 600;
  width: 25%;
  display: inline-block;
  margin: auto;
  padding: 2% 0% 4% 0%;
}

.social_img {
  width: 12%;
  padding: 0% 2%;
}

@media only screen and (max-width: 600px) {
  .social_img {
    width: 8%;
  }
}

.storelink {
  width: 95%;
  height: auto;
  margin: 11% 0% 0% 0%;
  cursor: pointer;
  display: block;
}

@media only screen and (max-width: 800px) {
  .storelink {
    width: 30%;
    margin: auto;
  }
}

.btouter {
  text-align: center;
  max-width: 6%;
}

.logincard {
  width: 33%;
  padding: 0% 2%;
}

@media only screen and (max-width: 800px) {
  .logincard {
    width: 80%;
    padding: 0% 2%;
  }
}

.sideimage {
  width: 100%;
  height: auto;
  padding: 12% 4%;
}

.loginsideimage {
  width: 100%;
  height: auto;
  margin: 12% 1%;
}

.valMsg {
  color: #ff0000;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, 1px) scale(0.75) !important;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 65%;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #800080;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.ui.search {
  border-radius: 0px !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  font-family: roboto !important;
  padding: 0.785714em 1.1em 0.78571429em 0em !important;
}

.MuiStepConnector-vertical {
  margin-left: 9px !important;
}

.MuiStepConnector-lineVertical {
  min-height: 30px !important;
  border-left-style: solid;
  border-left-width: 1px;
}

.MuiStepper-root {
  height: 555px !important;
  padding: 34px !important;
}

.makeStyles-input-131 {
  margin-bottom: 10px !important;
}

input#contained-button-file {
  display: none;
}

.css-2b097c-container {
  width: 100%;
}

input#date {
  text-transform: uppercase;
}

.btnspace {
  display: flex;
  max-width: 8.666667% !important;
  left: 18%;
}

.btnicon {
  width: 2.8rem !important;
  height: 2.8rem !important;
}

.mini-container {
  background: #fff;
  margin-top: 50px;
  display: inline-block;
  width: 100%;
  padding: 0 20px;
  margin-left: 0px;
}

label + div.MuiInput-formControl {
  margin-top: 9px;
}

.mini-container .MuiList-padding {
  padding-top: 0;
}

.MuiListItem-root input[type="radio"] {
  position: relative;
  top: 2px;
  margin-left: 5px;
}

.MuiListItem-gutters {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.MuiListItem-root input[type="text"] {
  padding-left: 0 !important;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}

.makeStyles-completed-13 {
  color: #fbf7f5 !important;
  font-size: 18px !important;
  background: #56b537 !important;
  padding: 2px;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
}

.MuiListItem-gutters .ui.fluid.dropdown {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  left: 128px;
}

.MuiSvgIcon-root.jss13 {
  color: #fbf7f5 !important;
  font-size: 18px !important;
  background: #56b537 !important;
  padding: 2px;
  border-radius: 50% !important;
  width: 20px !important;
  height: 20px !important;
}

.ui.selection.active.dropdown:hover,
.ui.selection.active.dropdown:hover .menu,
.ui.selection.active {
  border: 1px solid !important;
  border-color: #96c8da !important;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
}

.dt-bootstrap4 > div > .col-sm-12 {
  overflow-x: scroll;
}

div.MuiStepConnector-root {
  flex: unset;
}

.information-box {
  padding: 10px;
}

.information-box div {
  margin-bottom: 5px;
}

.information-content .MuiListItem-button {
  background-color: #ccc;
  margin-top: 35px;
  margin-left: 20px;
  margin-right: 20px;
}

.information-change .MuiListItem-button {
  background-color: #70c16f;
  margin-top: 35px;
  margin-left: 20px;
  margin-right: 20px;
}

button:focus {
  outline: none !important;
}

.mini-containers {
  background: #fff;
  margin: 29px -41px;
}

.content-header {
  top: 84px;
  padding: 2% 0% 0% 0%;
  width: 100%;
  margin-top: -24px;
}

.content-headers {
  display: inline-block;
  width: 100%;
}

.content-header-button {
  text-align: right;
}

.content-header-button button:last-child {
  margin-right: 0px !important;
}

div#root {
  width: 100%;
  display: inline-block;
}

.next-btn {
  text-align: right;
}

.content-headers-promoters {
  text-align: right;
}

.MuiContainer-root.MuiContainer-maxWidthLg {
  max-width: unset !important;
}

.content-headers-promoters button,
.content-header-button button,
.content-header-button button.MuiButtonBase-root:first-child {
  background-color: transparent;
  box-shadow: none;
  border: none;
  padding: 0;
  margin-right: 0;
}

.content-headers-promoters button:focus,
.content-header-button button:focus {
  outline: none;
}

.content-headers-promoters button:first-child svg,
.content-header-button button:first-child svg {
  height: 18px !important;
  width: 18px !important;
}

.content-headers-promoters button:first-child,
.content-header-button button:first-child {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #e0e0e0;
  padding: 5px 16px;
  margin-right: 10px;
}

.content-headers-promoters button:first-child:hover,
.content-header-button button:first-child:hover {
  background-color: #ababab;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.content-headers-promoters button:hover,
.content-header-button button:hover {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.content-headers-promoters button:nth-child(2n),
.content-header-button button:nth-child(2n) {
  color: #3f51b5;
}

.content-headers-promoters button svg,
.content-header-button button svg {
  height: 35px !important;
  width: 35px !important;
}

.banking_header {
  padding: 40px 10px;
  margin-bottom: 14px;
}

.notification_icon_part {
  display: flex;
  width: 100%;
  text-align: right;
  height: 100%;
  padding: 0 50px;
  align-items: center;
  justify-content: flex-end;
}

.notification_icon_part button:focus {
  outline: none;
}

.content-headers.not_fixed {
  position: unset !important;
  padding: 0;
}

@media (max-width: 1200px) {
  .mini-container {
    margin-left: 38px;
  }
}

@media (max-width: 1300px) {
  .mini-container {
    margin-left: 20px;
  }
}

@media (max-width: 1500px) {
  .header-right-part {
    margin-left: 50px;
  }
}

@media (max-width: 1110px) {
  .mini-container {
    padding: 0 0 0 40px;
  }
}

.floting_btn-right {
  position: absolute;
  right: 29px;
  bottom: -10px;
}

.margin_bottom {
  margin-bottom: 20px;
}

.margin_set_col {
  margin-top: 20px;
}

.d-flex.w-100.justify-content-end {
  display: none !important;
}

button.close {
  margin-left: 0 !important;
  padding: 20px 20px 20px 0 !important;
}

.closefloat {
  text-align: right;
  border-bottom: 1px solid #fff;
  padding: 10px 10px;
  background-color: #7254a3;
}

.bodyfloat {
  padding: 30px;
  background-color: lightgrey;
}

.floatopen {
  background-color: green;
  width: 30%;
  z-index: 1111;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  border-radius: 5px;
  transform: translate(-50%, -50%);
}

.loans_part h3,
.loans_part p {
  color: #000;
  margin: 0px;
}

.loans_parts h3 {
  color: #000;
  margin: 0px;
}

.loans_parts p {
  color: #000;
  margin: 0px;
}

.profile_table table tr td,
.profile_table table tr th {
  border: 1px solid #000;
  padding: 10px;
}

.profile_table table {
  width: 100%;
  text-align: center;
}

.profile_table table tr td {
  padding: 40px 20px;
}

.profile_table table tr td h4 {
  line-height: 28px;
}

.profile_table {
  width: 320px !important;
  background-color: #fff;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight.MuiPaper-elevation16 {
  top: 15.4em;
  box-shadow: none;
  height: unset !important;
  background-color: unset;
  overflow: unset;
  right: 16px;
}

.MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
  top: 60%;
  box-shadow: none;
  height: unset !important;
  background-color: unset;
  overflow: unset;
  left: 78%;
}

.MuiDrawer-paperAnchorDockedLeft .MuiList-root {
  padding: 0px !important;
}

.MuiBackdrop-root {
  background-color: unset !important;
}

.MuiDrawer-root.MuiDrawer-modal {
  position: unset !important;
  z-index: unset !important;
  right: unset !important;
  bottom: unset !important;
  top: unset !important;
  left: unset !important;
}

.open_right_view {
  position: fixed;
  right: 17px;
  top: 27.8em;
}

.open_right_view_btn {
  background-color: #3f51b5 !important;
  padding: 0px !important;
  min-width: auto !important;
  color: #fff !important;
  top: -100px;
}

.open_right_view_btn1 {
  background-color: #3f51b5 !important;
  padding: 0px !important;
  min-width: auto !important;
  color: #fff !important;
  top: -100px;
  left: 14%;
}

.close_view {
  position: absolute !important;
  top: 75px;
  left: -50px;
  background-color: #3f51b5 !important;
  padding: 0px !important;
  min-width: auto !important;
  color: #fff !important;
}

.close_view1 {
  position: absolute !important;
  background-color: #3f51b5 !important;
  padding: 0px !important;
  min-width: auto !important;
  color: #fff !important;
  top: 40%;
  left: -16%;
}

.open_right_view_btn svg,
.close_view span svg {
  font-size: 47px;
}

.open_right_view_btn1 svg,
.close_view1 span svg {
  font-size: 47px;
}

.status_page_body_part {
  display: flex;
  align-items: center;
}

.line_set {
  height: 100px;
  width: 2px;
  background-color: #3a3a3a;
}

.status_page_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left_text p,
.right-text p {
  margin: 0 !important;
}

.center_icon {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left_text,
.right-text {
  width: 190px;
  text-align: center;
}

.status_page_heading {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.border_set_pro {
  padding: 40px 5px 5px;
  text-align: center;
  box-shadow: -2px 1px 17px 1px #b8b8d2;
}

.btn_row_fixd {
  position: fixed;
  bottom: 15px;
  width: 98%;
  left: 11px;
  padding: 1em 3em;
  right: 12px;
}
.mg_btm_set {
  margin-bottom: 60px;
}
.mg_btm_set1 {
  margin-bottom: 0px;
}

.content-header-button button.MuiButtonBase-root:first-child {
  color: #3f51b5;
}

.content-header-button button.MuiButtonBase-root:first-child svg {
  height: 35px !important;
  width: 35px !important;
}

.MuiAppBar-colorPrimary {
  color: #3f51b5 !important;
  background-color: #ffffff !important;
  box-shadow: unset !important;
}

.MuiDivider-root {
  background-color: rgb(114, 84, 163) !important;
}

.makeStyles-toolbarIcon-3 {
  min-height: 65px !important;
}

.card1 .loans_part {
  background-color: #fff;
  height: 7pc;
  padding: 20px 20px;
  box-shadow: -2px 1px 17px 1px #b8b8d2;
  color: #000 !important;
  text-align: center;
  border: 1px solid #7254a3;
  border-radius: 0.7em;
}

.card2 .loans_part {
  background-color: #fff;
  height: 7pc;
  padding: 20px 20px;
  box-shadow: -2px 1px 17px 1px #b8b8d2;
  color: #000 !important;
  text-align: center;
  border: 1px solid #7254a3;
  border-radius: 0.7em;
}

.card3 .loans_part {
  background-color: #fff;
  height: 7pc;
  padding: 20px 20px;
  box-shadow: -2px 1px 17px 1px #b8b8d2;
  color: #000 !important;
  text-align: center;
  border: 1px solid #7254a3;
  border-radius: 0.7em;
}

.dt-bootstrap4 > div > .col-sm-12 {
  overflow: hidden !important;
}

.next_btn_div {
  width: 100%;
  text-align: right;
  margin-left: 87em;
}

.back_btn_div {
  width: 100%;
  text-align: right;
  margin-left: 75em;
}

.next_btn_div button {
  width: 76% !important;
}

.login-form {
  background: url(./image/background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Roboto";
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.2px;
}
.login_form_cpa {
  background-color: white !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Roboto";
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.2px;
}

.login-form1 {
  background: #fff;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Roboto";
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.2px;
}

.login-card {
  font-family: "Roboto";
  /* height: auto; */
  width: 70em !important;
  background-color: #fff;
  margin: auto;
  position: relative;
  height: 33pc !important;
}

.login-card1 {
  font-family: "Roboto";
  width: 70em !important;
  background-color: #fff;
  margin: auto;
  position: relative;
  height: 31pc !important;
}

.reg-card {
  font-family: "Roboto";
  padding: 2% 2%;
  width: 70em !important;
  background-color: #fff;
  margin: auto;
  position: relative;
}
.reg-card-cpa {
  font-family: "Roboto";
  padding: 2% 2%;
  width: 70em !important;
  background-color: #fff;
  margin: auto;
  position: relative;
  border: 1px solid gray !important;
}

.reg-btn {
  margin: auto;
  width: 50%;
  display: block;
}

.ctm_btn_set {
  font-family: "Roboto";
  color: #fff;
  border-radius: 50%;
  padding: 0.8em;
}

.ctm_btn_set.facebook {
  color: #fff;
  font-family: "Roboto";
  border-radius: 50%;
  padding: 0.8em;
  background-color: #29487d !important;
}

.google .material-icons {
  width: inherit !important;
}

.ctm_btn_set.linkdin {
  color: #fff;
  border-radius: 50%;
  font-family: "Roboto";
  padding: 1.09em;
  height: 4.2em !important;
  background-color: #007bb6 !important;
}

.head {
  display: flex;
  justify-content: space-evenly;
}

.ctm_btn_set {
  background-color: #ccc;
  padding: 20px;
  font-family: "Roboto";
  border-radius: 100%;
  min-width: auto !important;
}

span.MuiButton-startIcon.MuiButton-iconSizeMedium {
  margin: 0;
  font-family: "Roboto";
}

.ctm_btn_set.footer {
  width: 100%;
  border-radius: 0px;
  font-weight: 600;
  font-size: 120%;
  font-family: "Roboto";
  color: #ffffff;
  background-color: #3f51b5 !important;
  border: 1px solid #3f51b5 !important;
  box-shadow: unset;
  letter-spacing: 2px;
}

.ctm_btn_set.footers {
  width: 100%;
  border-radius: 0px;
  font-weight: 600;
  padding: 10px;
  font-size: 15px;
  font-family: "Roboto";
  color: #ffffff;
  background-color: #3f51b5 !important;
  border: 1px solid#3f51b5 !important;
  box-shadow: unset;
}

input.ctm_btn_setinput {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.MuiToggleButtonGroup-root {
  margin-left: 18px;
}

.otp_class .MuiTextField-root:first-child {
  padding: 0 14px 0 0px;
}

.otp_class {
  display: flex;
}

.footer p {
  font-size: 13px !important;
  text-align: inherit;
  word-spacing: 2px;
}

.footer p span {
  color: #007bb6 !important;
}

.footers p {
  font-size: 13px !important;
  text-align: inherit;
  word-spacing: 2px;
}

.footers p span {
  color: #007bb6 !important;
}

button.kep-login-facebook.metro {
  color: #fff;
  font-family: "Roboto";
  border-radius: 50%;
  height: 3.3em !important;
  padding: 1.1em 1.08em;
  background-color: #29487d !important;
  border-color: unset !important;
}

.ctm_btn_set.google {
  color: #fff !important;
  border-radius: 50% !important;
  background-color: #d74b3c !important;
  width: 47px;
  height: 3.6em !important;
  padding: 14px 14px !important;
  display: block !important;
  border: 0 !important;
  box-shadow: unset !important;
}

.ctm_btn_set.google div {
  display: none;
}

.modal_sign button {
  padding: 10px 27px;
  font-size: 16px;
  border-radius: 9px;
}

.modal_sign .modal-title.h4 {
  font-size: 27px;
}

.modal_sign.modal {
  top: 196px;
}

.modal_sign .close {
  font-size: 24px;
}

.text_start textarea {
  height: 31.2em !important;
}

.MuiAppBar-positionStatic :hover {
  color: black;
}

.ctm_btn_set.otp {
  width: 100%;
  border-radius: 0px;
  padding: 10px;
  font-family: "Roboto";
  background: #f50057;
  border: 1px solid #f50057;
  color: #ffffff;
}

.ctm_btn_set.otp:hover {
  color: #f50057;
  background: #ffffff;
  border: 1px solid #f50057;
}

.MuiButton-root.Mui-disabled {
  color: #d6d6d6 !important;
}
.activeLoan table thead tr th:last-child {
  width: 110px;
}
.navbutton {
  width: 85%;
  height: auto;
}

.navgraybutton {
  width: 85%;
  height: auto;
}

.makeStyles-button_div-63 {
  width: 100%;
  margin: 25px !important;
  font-size: 14px;
  text-transform: capitalize;
}

.react-tel-input .form-control {
  border: none !important;
  border-bottom: 2px solid #bbbbbb !important;
  border-radius: 0px !important;
  width: 115px !important;
  font-family: "Roboto" !important;
  font-size: 95% !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fff !important;
  border: none !important;
  border-radius: 0px !important;
}

.react-tel-input .selected-flag {
  padding: 0px !important;
  border-radius: 0px !important;
  border-bottom: 2px solid #bbbbbb;
}

.actionBtn {
  min-width: 10px !important;
}

.modalHeader {
  font-size: 155%;
  font-weight: 600 !important;
  letter-spacing: 2px;
}

.modalMsg {
  font-size: 115%;
  text-align: justify;
  font-family: "Roboto";
  font-weight: 400;
}

.modalContBtn {
  width: 100%;
  background-color: #07006f !important;
  color: #ffffff !important;
  font-size: 100% !important;
  font-weight: 600 !important;
  margin-top: 15% !important;
}

.modalCancelBtn {
  width: 100%;
  background: #cecece !important;
  color: #000000 !important;
  font-size: 100% !important;
  font-weight: 600 !important;
  margin-top: 15% !important;
}

.forgotid {
  cursor: pointer !important;
  font-family: "Roboto";
  font-size: 100%;
  color: blue;
  font-weight: 600;
}

@media only screen and (min-width: 360px) and (max-device-width: 640px) {
  .forgotid {
    font-size: 57%;
  }
}

@media only screen and (min-width: 641px) and (max-device-width: 800px) {
  .forgotid {
    font-size: 130%;
  }
}

@media only screen and (min-width: 801px) and (max-device-width: 1023px) {
  .forgotid {
    font-size: 82%;
  }
}

@media only screen and (min-width: 1024px) and (max-device-width: 1280px) {
  .forgotid {
    font-size: 63%;
  }
}

@media only screen and (min-width: 280px) and (max-device-width: 359px) {
  .forgotid {
    font-size: 49%;
  }
}

.datable .actionBtn {
  opacity: 1;
}

.MuiAccordionDetails-root {
  display: block !important;
}

.backBtn {
  font-size: 14px !important;
  font-weight: 400 !important;
  float: right !important;
  margin-top: 100px !important;
}
.backBtns {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin: 10px !important;
  cursor: pointer;
}

.tablecontainer {
  margin-top: 3% !important;
}

.reporttablecontainer {
  margin-top: -5% !important;
}

.makeStyles-reqired-55 {
  border: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.MuiListItem-gutters .ui.fluid.dropdown {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.otpsent {
  font-size: 80%;
  margin-bottom: 0;
  color: #848484;
}

.MuiTab-textColorPrimary {
  color: #000000 !important;
  font-weight: 600 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #ffffff !important;
  background: #7354a2;
  font-weight: 600;
  letter-spacing: 1.3px;
}

.PrivateTabIndicator-colorPrimary-36 {
  background-color: #7354a2 !important;
}

.PrivateTabIndicator-colorPrimary-42 {
  background-color: #7354a2 !important;
}
.MuiTabs-flexContainer{
  width: 100%;
}
.MuiTab-root{
  flex: 1 1 auto;
  text-align: center;
  width:33.33%!important;
  max-width: 33.33%!important;
}
.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background: #7354a2 !important;
  color: #fff;
}

.PrivateTabIndicator-colorSecondary-37 {
  background-color: #7354a2 !important;
}

.accordian_head {
  background-color: #7254a3 !important;
}

#addBank .MuiPaper-root.MuiAccordion-root #panel1bh-header .MuiAccordionSummary-content p{
  color: #ffffff!important;
}
#addBank .MuiSvgIcon-root{
  color: #ffffff;
}

.updatebtn {
  margin: 5% 0% !important;
  color: #fff !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  background: rgb(114, 84, 163) !important;
}
.updatebtn:hover {
  background-color: rgb(111, 49, 209) !important;
}
.WithStyles\(ForwardRef\(AccordionSummary\)\)-root-18 {
  min-height: 0px !important;
}
#financial-business-wrap .MuiPaper-root > .MuiButtonBase-root.accordian_head{
  background-color: #7254a3!important;
  color: #ffffff;
}
#financial-business-wrap .MuiPaper-root > .MuiButtonBase-root.accordian_head .MuiButtonBase-root span{
  color: #ffffff;
}
.MuiAccordionSummary-content {
  margin: unset !important;
}

.WithStyles\(ForwardRef\(AccordionDetails\)\)-root-21 {
  padding: 3px !important;
}

.countRowNumber {
  border-radius: 20%;
  padding: 10%;
  background: rgb(0, 170, 23);
  color: #fff;
  text-align: center;
  font-size: 14px;
}

.fc .fc-view-harness-active > .fc-view {
  position: absolute !important;
  top: 0 !important;
  right: 50px !important;
  bottom: 100px !important;
}
.countRowNumberNew{
  border-radius: 20%;
  padding: 8px 10px;
  background: green;
  color: #fff;
  text-align: center;
  font-size: 14px;
  min-width: 50px;
  display: inline-block;
}
.countRowNumberForRed {
  border-radius: 20%;

  padding: 10%;
  background: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 14px;
}

.countRowNumber1 {
  border-radius: 100%;
  width: 20%;
  padding: 4%;
  background: rgb(0, 170, 23);
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  cursor: pointer !important;
}

.countRowNumberForRed1 {
  border-radius: 100%;
  width: 20%;
  padding: 4%;
  background: #ff0000;
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  cursor: pointer !important;
}

.countRowNumberFinance {
  border-radius: 4px;
  width: 90px;
  padding: 2px 6px;
  background: transparent;
  color: #000000;
  text-align: center;
  font-size: 14px;
  min-width: 90px;
  display: inline-block;
  border: 1px solid #00aa17;
}

.countRowNumberForRedFinance {
  border-radius: 4px;
  width: 90px;
  padding: 2px 6px;
  background: transparent;
  color: #000000;
  text-align: center;
  font-size: 14px;
  min-width: 90px;
  display: inline-block;
  border: 1px solid #ff0000;
}

.MuiDialog-paperWidthSm {
  width: 100%;
  height: auto;
  padding: 2%;
}

.modaltext {
  color: rgb(29, 27, 27);
  font-weight: 600;
}

.modaltextfail {
  color: #ff0000;
  font-weight: 600;
}

.modalChild {
  margin: auto;
  display: block;
  padding: 0% 10%;
}

.modelDivPadding {
  padding: 20px 20px !important;
}

.pg-viewer-wrapper {
  overflow-y: unset !important;
}

.photo-viewer-container {
  width: unset !important;
  height: unset !important;
}

.photo-viewer-container > img {
  height: unset !important;
}
.MuiTab-root {
  min-width: auto;
}

.tabSize[aria-selected="false"] {
  color: #330080 !important;
  background: #ededed !important;
}

.styles_otpInput__2umfM {
  width: 100% !important;
  height: auto !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  outline: none !important;
  border-radius: 0% !important;
  text-align: center !important;
  margin-top: 10px !important;
  font-size: 100% !important;
}

.hidden_number {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #0000a7;
  margin-left: 8px;
}

.otpfieldsReg {
  margin-top: "-3px !important";
}

.otptimerReg {
  margin-top: -9px !important;
}

.dialogHeader {
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  color: white;
  background-color: rgb(114, 84, 163);
  margin-top: 0px !important;
  margin-left: 0 !important;
  padding-left: 1.5em;
}
p {
  line-height: 2em !important;
}

.dialogFooter {
  margin-top: 5%;
}

.bankingSubHeader {
  font-size: 18px;
  color: rgb(114, 84, 163);
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-top: 0px !important;
}

.dotred {
  background: red;
  width: 7%;
  height: 90% !important;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  padding: 2% 2%;
  color: #fff;
}
.pg-viewer-wrapper {
  height: 100% !important;
  padding: 20px 5px !important;
  overflow-y: unset !important;
}

.dotgreen {
  background: green;
  width: 7%;
  height: 90% !important;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  padding: 2% 2%;
  color: #fff;
}

.MuiDropzoneArea-root {
  min-height: 60px !important;
}

.docUploadRow {
  padding: 5px;
  border: 0.5px solid rgb(231, 231, 239);
}

.subHeaderStyle {
  background-color: #e7e7ef !important;
  width: 100% !important;
  padding: 10px !important;
}

.MuiDropzoneArea-icon {
  display: none !important;
}

.MuiDropzoneArea-text {
  margin-top: 12px !important;
  margin-bottom: 0px !important;
}

.letgetsHeader {
  font-size: 25px;
  color: #7254a3;
  font-weight: 600;
  letter-spacing: 1.2px;
}

.MuiButtonGroup-root {
  width: 15% !important;
}

.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
  width: 100% !important;
}

.docUploadRow .fileUploadAction {
  opacity: 0;
}

.docUploadRow:hover .fileUploadAction {
  opacity: 1;
}

.applicationTableDetails {
  float: right;
  background-color: #eee;
  font-weight: bolder;
  margin: auto;
  font-size: 10px;
  color: #3f51b5;
}

.applicationTableDetailsBack {
  background-color: #eee;
  font-weight: bolder;
  margin: auto;
  width: 65%;
  font-size: 10px;
  color: #3f51b5;
  margin-right: 1%;
}

.applicationTableDetails1 {
  float: left;
  font-weight: bolder;
  margin: auto;
  font-size: 14px;
  color: #990000;
}

.datepickerError {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: -3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333;
}

.MuiTab-root {
  font-size: 16px !important;
  padding: 0px 0px !important;
  min-height: 36px !important;
}
#scs-main-tab .MuiTab-root {
  min-height: 48px !important;
}
#LoanPagination .mdb-datatable.dt-bootstrap4 > .row > .d-flex.w-100.justify-content-between {
  position: absolute;
  top: -56px;
  left: 5px;
}
.MuiTabs-root {
  min-height: 36px !important;
}
#financial-business-wrap .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
  background-color: #7254a3;
}
#financial-business-wrap .MuiSwitch-colorSecondary.Mui-checked{
  color: #7254a3;
}
.banfFormValid {
  color: #f44336;
  font-size: 0.75rem;
  margin-top: -10px;
  letter-spacing: 0.03333em;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.fa-times-circle {
  color: red !important;
  font-size: 15px !important;
}

.choicebtn {
  font-size: 100% !important;
  font-weight: 600 !important;
  border: 2px solid !important;
  color: #3f51b5 !important;
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.backbutton {
  width: 25%;
  height: auto;
  float: right;
  min-width: 48px;
}

.MuiDialogTitle-root {
  padding: unset !important;
}

.dashboarddd {
  padding: 5% 2%;
  width: 17ch;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.dashcard {
  border: 1px solid rgb(97 186 0);
  background: #d1e3d1;
  width: 60%;
  margin: 1% auto;
  display: block;
}

.dashcardvalue {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.dashcardlabel {
  font-size: 14px;
  font-weight: 600;
}

.image:hover .navbutton {
  display: none;
}

.image:hover .navgraybutton {
  display: block;
}

.navgraybutton {
  display: none;
}

.warningMsg {
  position: fixed;
  bottom: 0;
  width: 94%;
  margin-bottom: 0.8%;
  background-color: #ffef7e !important;
}

.warningMsg1 {
  background-color: #ffef7e !important;
}

.MuiAlert-icon {
  display: block !important;
}

.svgicon {
  width: 16px;
  margin: 0% 3%;
  cursor: pointer;
  margin: 14% 4%;
}

.mainheader1 {
  margin: 1% 0%;
}

.subHeader1 {
  margin: 0% 0% 2% 0%;
}

.subheader2 {
  margin: -1% 0% 1% -1%;
}

.mainHeaderBack {
  margin: 1% 0% 0% 0%;
}

.tableSLNoHeading {
  font-weight: 600 !important;
  font-size: 24px;
  margin: 0px 10px !important;
}

.tableBusinessNameHeading {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 30% !important;
  text-align: justify !important;
}

.tableBusinessNameHeadingMain {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 15% !important;
  text-align: justify !important;
}

.tableBusinessNameHeadingMainPage {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 25% !important;
  text-align: justify !important;
}

.tableGSTHeading {
  font-weight: 600 !important;
  font-size: 100% !important;
  text-align: justify !important;
}

.tablePANHeading {
  font-weight: 600 !important;
  font-size: 100% !important;
}

.tableEntityTypeHeading {
  font-weight: 600 !important;
  font-size: 100% !important;
  text-align: justify !important;
}

.tableStatusHeading {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 4% !important;
  text-align: center !important;
  padding: 0% 0% !important;
}

.tableStatusHeadingsummary {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 4% !important;
}

.tableActionHeading1 {
  font-weight: 600 !important;
  font-size: 100% !important;
  text-align: center !important;
  padding: 7px 0% !important;
  width: 30% !important;
}

.tableActionHeading {
  font-weight: 600 !important;
  font-size: 100% !important;
  text-align: center !important;
  padding: 7px 0% !important;
  width: 15% !important;
}

.tableActionHeadingNewWidth {
  font-weight: 600 !important;
  font-size: 100% !important;
  text-align: center !important;
  padding: 7px 0% !important;
  width: 18% !important;
}

.tableBusinessPan {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 10% !important;
  text-align: justify !important;
}

.tableLoanProduct {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 12% !important;
  text-align: justify !important;
}

.tableApplicationType {
  font-weight: 600 !important;
  font-size: 100% !important;
  width: 14% !important;
  text-align: justify !important;
}

.mainpagedd {
  display: block !important;
  margin: 0% -130% !important;
}

.documentuploadHeader {
  margin: 0% 0% 1% -0.7%;
}

.docUplHeader {
  border: 0.5px solid rgb(231, 231, 239);
  width: 100%;
  display: flex;
  background: rgb(231, 231, 239);
  padding: 0.5% 0%;
}

.docAddBtn {
  display: block;
  margin: auto !important;
  top: 35%;
  left: 70%;
  position: absolute;
}

.docDelBtn {
  display: block;
  margin: auto !important;
  top: 35%;
  left: 48%;
  position: absolute;
}

.docbackbtn {
  margin: -60% 0%;
}

.uploadedFileName {
  font-size: 14px !important;
  color: rgb(114, 84, 163);
}

.docuploadicon {
  width: 30%;
}

.uploadedImageView {
  width: 45px;
  padding: 5px 0px;
}

.docName {
  font-size: 14px;
  margin-bottom: 1%;
  margin-left: 20px;
}

.docUplDate {
  font-size: 14px;
  margin-left: 20px;
}

.MuiDialog-paperScrollPaper {
  border: 2px solid rgb(114, 84, 163) !important;
}

.modal-title {
  margin-top: -3%;
  border-bottom: 2px solid rgb(114, 84, 163) !important;
  margin-top: -30px !important;
  width: 111.4% !important;
  margin-left: -6% !important;
  background-color: rgb(231, 231, 239) !important;
}

.title-header {
  font-weight: bold !important;
  margin: auto !important;
  margin-left: 2% !important;
}

.applicationLoanImg {
  width: 50% !important;
  height: 50% !important;
}

.MuiTableRow-root.Mui-selected,
.MuiTableRow-root.Mui-selected:hover {
  background-color: rgb(126 126 126 / 50%) !important;
}

.perfios_card_header {
  text-align: center;
  font-size: 25px !important;
  font-weight: 600 !important;
  color: #7254a3 !important;
}

#perfios_main_card {
  box-shadow: 0 0px 5px 0 rgb(0 0 0 / 35%), 0 1px 5px 0 rgb(0 0 0 / 35%) !important;
}

.perfios_card_para {
  font-size: 15px !important;
  text-align: center;
}

.profileInfo {
  width: 100%;
  text-align: justify;
  padding: 3% 4px !important;
  border: 1px solid #878787;
  border-radius: 2%;
  box-shadow: 2px 8px 24px -3px rgb(0 0 0 / 29%);
  -webkit-box-shadow: 2px 8px 24px -3px rgb(0 0 0 / 29%);
  -moz-box-shadow: 2px 8px 24px -3px rgba(0, 0, 0, 0.29);
}

.profiletable {
  font-size: 13px;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 2px;
  border-bottom: 1px solid #a0a0a0;
}

.profileDate {
  margin-bottom: 5px;
  font-size: 87%;
}

.quil_editor {
  width: 80% !important;
  max-width: 80% !important;
}

.quil_editor2 {
  width: 76.6% !important;
  max-width: 76.5% !important;
}

.quil_editor3 {
  width: 74.5% !important;
  max-width: 74.5% !important;
}

.fixedHeader {
  position: sticky;
  top: 0;
  background: #fff;
}

.progresslabel {
  margin-bottom: 0%;
  float: right;
  width: 30%;
  font-weight: 600;
}

.crif_table {
  border-collapse: collapse;
  width: 100%;
  height: 102px;
}

.crif_table_row {
  height: 17px;
}

.crif_table_data1 {
  width: 20%;
  height: 17px;
  text-align: right;
  font-weight: bold;
}

.crif_table_data2 {
  width: 30%;
}

.crif_table2 {
  width: 70%;
  height: 17px;
}

.crif_header {
  font-weight: bold;
}

.crif_table_data3 {
  width: 100%;
  height: 17px;
  text-align: right;
}

.crif_header2 {
  width: 180px !important;
  text-align: center;
  white-space: nowrap;
}
.crif_td {
  width: 180px !important;
  text-align: center;
  white-space: nowrap;
}
.crif_tr:nth-child(even) {
  background: #ccc;
}
.crif_tr:nth-child(odd) {
  background: #fff;
}

.headersecond {
  background-color: #ffefcb;
}

.crif_main_header {
  background-color: #007bb6 !important;
}

.crif_table_sl {
  width: 50px !important;
  text-align: center;
}

.month_header {
  background-color: #97ebf4;
}

.crif_year {
  background-color: #97ebf4;
}

.stand_header {
  margin-top: -2% !important;
  margin-bottom: 1% !important;
}

.crifheading {
  width: 100%;
  background-color: #90caa4;
  padding: 2px 8px;
  font-weight: 600;
}

.inqHead {
  padding: 1% 0% 0% 0%;
  font-weight: 600;
  color: #282866;
}

.inqResult {
  padding: 1% 0% 0% 0%;
}

.crifhighheading {
  width: 100%;
  background-color: #c5f3d5;
  padding: 2px 8px;
  font-weight: 600;
}

.crifscoreinfo {
  float: right;
  font-size: 10px;
  padding: 8px 4px;
}
.MuiTablePagination-root {
  color: rgba(0, 0, 0, 0.87);
  overflow: hidden !important;
  font-size: 0.875rem !important;
}

.crifscore {
  padding: 29px;
  background: rgb(219, 238, 254);
  font-size: 20px;
  position: relative;
  border-radius: 12px;
  font-weight: 600;
  color: #00009f;
}

.stand_alone_search {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  float: right;
  width: 15%;
  background: #f1f1f1;
  margin-bottom: 6px;
  height: 30px;
  border-radius: 5px;
  overflow: none;
}

.jodit-wysiwyg_mode {
  width: 85% !important;
}

.bankTxt {
  width: 10px !important;
}
.bankTxt1 {
  width: 150px !important;
}

.tableFixHeader {
  overflow-y: auto;
  max-height: 600px;
}

.sticky_header {
  background-color: #fff;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.toolbarhide {
  margin-left: 88% !important;
  position: absolute !important;
  margin-top: 14px !important;
}

.letgetreq {
  overflow-y: scroll;
  height: 15%;
}

.docheader {
  font-size: 16px !important;
}
.docli {
  font-size: 14px !important;
}

.ForwardRef-root-122 {
  height: 35px !important;
  width: 18% !important;
  border: 1px solid black !important;
  float: right !important;
}

.search_bar {
  height: 35px !important;
  width: 100%;
  border: 1px solid rgb(51, 31, 231) !important;
  float: right !important;
  max-width: 280px;
  display: none;
}

.tippy_para {
  margin-top: -10px !important;
}

.tippy_ul {
  margin-left: "-25px" !important;
}
#nested-list-subheader1 {
  font-size: 21px !important;
  font-weight: "bold" !important;
}

.aproved_amnt {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.bank_name {
  font-size: 18px !important;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.fileviewer {
  /* position: fixed; */
  z-index: 1;
  height: 100vh;
  margin-top: 25px;
  overflow-y: auto;
}
.fileviewer_type {
  position: fixed;
  z-index: 1;
  height: 50vh;
  margin-top: 25px;
  overflow-y: auto;
}
.banking_fileviewer {
  margin-top: 45% !important;
  height: 90% !important;
}
.custom_modal {
  height: 100% !important;
  max-height: calc(100% - 30px) !important;
}

.bank_modal_type {
  margin-bottom: 10px !important;
}

.bankTxt1 {
  width: 160px !important;
  height: 40px !important;
}

.comment-box {
  color: #100c0c !important;
  background-color: rgb(241, 239, 240) !important;
  border-radius: 0.875rem !important;
  padding: 0.2rem 1rem 1.5rem !important;
}

.comment-form {
  background-color: #fff !important;
  border-radius: 0.25rem !important;
  margin-bottom: 2rem !important;
  padding: 1rem !important;
}

.comment-form-fields {
  margin-bottom: 0.25rem;
}

.comment_textfield {
  border: none;
  border-bottom: 1px solid;
  font-size: 0.85rem;
  padding: 0.2rem 0;
  width: 99%;
  font-style: italic;
}

#comment-reveal {
  float: right;
  background-color: #00bdfc;
  color: #fff;
}
.comment {
  border-top: 0.125rem solid #404040;
}
.comment-body {
  font-style: italic;
  margin-left: 1rem;
}
.comment-footer {
  margin-top: -6px !important;
  margin-bottom: 6px !important;
}
.comment-footer-delete {
  padding: 0.2rem 0;
  color: #404040;
  text-decoration: none;
  font-size: 14px;
  text-align: right;
}

.comment_button {
  border: none;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.75em;
  display: inline-block;
  text-decoration: none;
}

.comment-header {
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.comment_btn {
  appearance: auto;
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
  box-sizing: border-box;
  margin: 0em;
  font: 400 13.3333px Arial;
}

.sidenav a {
  position: absolute;
  right: -198px;
  transition: 0.8s linear;
  padding: 15px;
  width: 150px;
  font-size: 20px;
  color: white;
  font-weight: 600;
  border-radius: 5px 5px 5px 5px;
}

.sidenav a:hover {
  text-decoration: none;
  right: 0;
  z-index: 1;
}

#about {
  top: -60px;
  background-color: #a621f3;
}

.cpa_comments {
  text-align: end !important;
}

.cpModal {
  background: none !important;
  border-bottom: none !important;
  margin-top: 0px !important;
  margin-left: 0 !important;
}

.modalHead {
  background: rgb(114, 84, 163);
  color: #fff !important;
}

.close {
  color: #fff !important;
  opacity: 1 !important;
}

.modelwdth {
  width: 85% !important;
  height: 100% !important;
}
.MuiDialog-paperWidthSm {
  padding: 0% !important;
}
.modaltext {
  padding: 5%;
}
.modalText {
  padding-left: 1.5em;
}
.MuiTableCell-sizeSmall {
  padding: 0px 6px!important;
}
.MuiTableRow-root {
  margin: 10px 0px !important;
}
.MuiTableRow-root th.MuiTableCell-root {
  vertical-align: top;
}
.modaltexts {
  padding: 5%;
  padding-left: 1.5em;
  margin-left: 80px !important;
}

.mainHead {
  width: 100%;
  background: rgb(114, 84, 163);
  border-radius: 4px 4px 0px 0px;
}

.headTxt {
  font-size: 25px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.2px;
  margin-left: 0px;
  padding: 1%;
}

.logoImg {
  margin: 4% !important;
}

.sheet-names {
  display: none !important;
}

.select_lakh {
  padding: 4px 0px 6px 12px !important;
}
button,
input .bank_input {
  border: none !important;
  border-bottom: 1px solid #000;
}
.column-2 {
  columns: 2 auto;
}
.button-add {
  margin-left: 750px !important;
  margin-bottom: -55px !important;
}
.MuiStepper-root {
  display: flex !important;
  padding: 2.5rem !important;
}

.error-template {
  padding: 40px 15px;
  text-align: center;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
}
.error-actions .btn {
  margin-right: 10px;
}

.preview_eye {
  background-color: rgb(0 182 20) !important;
}

.bank-table {
  display: block !important;
  overflow-x: auto !important;
  white-space: nowrap !important;
}

.rental_details {
  margin-top: 10% !important;
  margin-left: 16px !important;
}

.bank_caption {
  caption-side: top !important;
  font-weight: 500;
}

.gstcalheader {
  font-weight: 600;
}

.bankin_main_header {
  font-weight: bold;
  font-size: 16px;
}

.finance_preview_td {
  width: 100px !important;
  max-width: 100px;
  text-align: center !important;
  max-width: 100px;
}
.finance_preview_td .MuiInputBase-input{
  text-align: right;
}
.bank_dynamic_row {
  width: 40px;
  max-width: 40px;
  font-size: 15px;
  font-weight: 400;
}

.bank_preview_header {
  font-weight: bold;
  padding: 0px 5px;
}

.view {
  width: 440px !important;
  height: 607px !important;
  overflow: auto !important;
  margin: 10px 0px !important;
}

.view2 {
  margin: auto !important;
  width: 440px !important;
  height: 607px !important;
  margin-right: 20px !important;
  overflow: auto !important;
}

.wrapper {
  position: relative !important;
  overflow: auto !important;
  overflow-y: auto !important;
}

.sticky-col {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.first-col {
  width: 100px !important;
  min-width: 100px !important;
  max-width: 100px !important;
  left: 0px !important;
}

.bank_button {
  width: 100px !important;
}

.first-col2 {
  width: 143px !important;
  min-width: 100px !important;
  max-width: 288px !important;
  left: 0px !important;
  z-index: 10;
  word-wrap: break-word !important;
  font-weight: 700;
}
.tableSLNoHeading {
  width: 50px;
  min-width: 50px;
}
.actionHeader {
  width: 70px;
  min-width: 70px;
  text-align: center!important;
}
.financerHeader{
  width: 200px;
  min-width: 200px;
}

.styled-table-bank {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
}

.styled-table2 {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.tr_header {
  height: 43px !important;
}

.header-right-part {
  z-index: 9999;
}

.second_header {
  background-color: #f3f3f3 !important;
  text-align: left;
}

.loan_header {
  font-size: 18px !important;
  text-align: center !important;
}

.first_modeel_header {
  margin-bottom: 14% !important;
  margin-top: 14% !important;
}

.otp_screen {
  margin-left: 28%;
}

.sum_para {
  font-weight: 600;
}

.sum_first_row {
  margin-bottom: 1%;
}

.sum_second_row {
  margin-bottom: 1%;
}

.lgs_header1 {
  font-size: 14px;
  color: rgb(114, 84, 163);
  font-weight: bold;
  letter-spacing: 1.2px;
  margin-bottom: 3% !important;
}

.loan_details {
  margin: 0 0 0 0 !important;
}

.sum_logo {
  text-align: right;
  margin: 1% 1% -2% 0%;
}

.sum_footer {
  text-align: center;
}

.sum_headers {
  padding: 0 2% !important;
}

.sum_pl {
  width: 25% !important;
}

.sum_row:nth-child(even) {
  background-color: #f2f2f2;
}

.mini-container1 {
  background: #fff;
  margin-top: 25px;
  display: inline-block;
  width: 100%;
  padding: 0 20px;
  margin-left: 0px;
}

.itr_mod {
  margin: -2% !important;
}

.backBtn1 {
  margin-bottom: 2% !important;
}

.error_message_itr {
  color: red;
  font-size: 12px !important;
}
.table_wrapper {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.backBtn3 {
  margin-bottom: 2% !important;
}

.calculated_row {
  border-left: 1px solid black !important;
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
  border-right: 1px solid black !important;
}

.loan_cal_row {
  font-weight: 600 !important;
}

.cal_row1 {
  text-align: center !important;
}

.css-2b097c-container {
  width: 70% !important;
  margin-left: 15% !important;
}

.photo {
  width: 100% !important;
  height: 80% !important;
  padding: 4em;
  margin-top: -50px !important;
}

#pg-photo-container {
  width: 552px !important;
  height: 100% !important;
}

.pg-viewer-wrapper {
  height: 95% !important;
}

.fileformatted {
  margin: left 40px !important;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  border: 4px solid rgb(188, 30, 219) !important;
}

.document_text {
  font-size: 16px !important;
  color: rgb(114, 84, 163);
  font-weight: 400;
  letter-spacing: 1.2px;
}

.MuiDropzoneArea-root{
  background-color: rgb(231, 231, 239) !important;
   border: 1px solid  rgb(114, 84, 163) !important; 
   width: 70% !important;
   margin: 8px 0px !important;
   
}

.MuiDropzoneArea-text{
  font-size: 16px !important;
}

.document_name{
  text-align: center;
}

.document_name1{
  text-align: center;
  margin-left: -7%;
}

.master_banks{
  text-align: center !important;
}
.update_btn_bank{
  text-align: center;
  margin-top: 5%;

}

.go2139093995 .dropdown-content{
  background: white !important;
 
}

.go2642161244{
  margin-bottom: 10px;
}
.go2642161244 .dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  background: #f7f7f7 !important;
  padding: 15px !important;

}

.go2642161244 .dropdown-heading-value span:first-child{
  background: #f7f7f7 !important;
}

.tickwithcircle{
  color: #ffffff;
  width: 15px;
  height: 15px;
  font-size: 18px;
  border-radius: 50%;
  background-color: green;
  fill: currentColor;
}

table.mdb-dataTable {
  border: 1px solid #ddd;
}

table.mdb-dataTable thead th{
  vertical-align: top;
  background-color: #7354a2;
  color: #ffffff;
}

table.MuiTable-root {
  border: 1px solid #ddd;
}

table.MuiTable-root thead th{
  vertical-align: top;
  background-color: #7354a2;
  color: #ffffff;
  padding:16px!important;
}
.MuiPaper-root.search_bar{
  /* margin-top: 20px; */
  margin-bottom: 10px;
  display: none;
}