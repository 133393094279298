.App {
  text-align: center;
}

.MuiFormControl-root.MuiTextField-root,
.MuiOutlinedInput-input,
.MuiFormControl-root,
textarea {
  width: 100% !important;
}
.headerField > .MuiFormControl-root.MuiTextField-root, .headerField > .MuiOutlinedInput-input, .headerField > .MuiFormControl-root {
  width: 50px !important;
}
.personalBox {
  display: block;
  width: 80;
  padding: 50px;
  box-shadow: -1px 1px 16px 11px #f8f9fe;
  height: auto;
  margin-top: 18px;
}
.form {
  text-align: left;
  font-size: calc(15px + 4vmin);
  font-weight: 500;

  background-color: #282c34;
  height: 42px;

  color: white;
  padding: 1.6rem;
}
.title {
  text-align: left;
  background-color: gray;
  height: 28px;
  padding: 1.6rem;
  margin-top: 0.1rem;
  font-size: calc(12px + 2vmin);
  font-weight: 500;
}
.inbox {
  background-color: red;
  height: 400px;
  margin-top: 0.2rem;
  width: 100%;
}
.textbox {
  text-align: left;
  align-items: inherit;
  flex-direction: row;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.MuiOutlinedInput-input {
  padding: 15.5px 14px;
}
.modal-dialog {
  max-width: 700px !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
