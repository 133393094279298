.option_name {
  font-size: 16px;
  font-weight: 600;
}

/**** Nitin Start****/

.lgs_header {
  /* text-align: center; */
  font-size: 20px;
  color: rgb(114, 84, 163);
  font-weight: bold;
  letter-spacing: 1.2px;
  padding: 10px 0px;
  line-height: 30px !important;
}

.rightline {
  border-right: 2px solid #d1d1d1;
}

 /*.lgs_tab {
  /* margin-top: -25px !important; */
  /* padding: 0% 0% 0% 5% !important; */
  /* transform: translate(7%, -3%);
}  */

/**** Nitin End****/